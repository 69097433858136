import Generator from './generator.js';

class ApiVersionGenerator extends Generator {
  constructor(options) {
    super(options);
  }

  transferToRegionMap(endpoints) {
    var map = {};
    for (var i = 0; i < endpoints.length; i++) {
      var endpoint = endpoints[i];
      var regionKey = (endpoint.locations || []).sort().join(',');
      var regionList = map[regionKey] || [];
      regionList.push(endpoint);
      map[regionKey] = regionList;
    }
    return map;
  }

  transferToList(map) {
    var list = [];
    var values = Object.values(map);
    for (var i = 0; i < values.length; i++) {
      list.push(...values[i]);
    }
    return list;
  }

  arrayToString(array) {
    return (array || []).sort().join(',');
  }

  compareArrayIgnoreOrder(array1, array2) {
    return this.arrayToString(array1) == this.arrayToString(array2);
  }

  /**
   * Check whether the afec is a subset of the rules.
   * 
   * @param {array} afec given afec list
   * @param {array} rules RequiredFeatures defined in ARM manifest
   */
  afecMatchedRules(afec, rules, featuresRule) {
    if (!afec || afec.length == 0) {
      // if the looked rule is empty, return only if the requiredFeatures is empty.
      return rules.length == 0;
    }

    // If all of these features are signed up, user will see and be able to register the provider.
    if (featuresRule.requiredFeaturesPolicy === "All") {
      return this.compareArrayIgnoreOrder(afec, rules);
    }

    // If any of these features are signed up, user will see and be able to register the provider.
    // if rules contains add elements in afec, then SET(afec + rules) should equal to SET(rules)
    var totalSet = Array.from(new Set([...rules, ...afec]));
    return this.compareArrayIgnoreOrder(totalSet, rules);
  }

  findItemIndexesByAfec(endpoints, afec) {
    var result = [];
    for (var i = 0; i < endpoints.length; i++) {
      var item = endpoints[i];
      if (this.afecMatchedRules(afec, item.requiredFeatures || [], item.featuresRule || {})) {
        result.push(i);
      }
    }
    return result;
  }

  updateVersionInLocations(list, defaultApiVersion) {
    return list;
  }

  updateVersionToResourceType(resourceType) {
    var endpoints = resourceType.endpoints;
    if (!endpoints || !endpoints.length) {
      return;
    }
    console.log('Working for ResourceType ' + resourceType.name);
    var regionEndpointsMap = this.transferToRegionMap(endpoints);
    var locations = Object.keys(regionEndpointsMap);
    for (var i = 0; i < locations.length; i++) {
      var key = locations[i];
      console.debug('Add API version to location ' + key);
      regionEndpointsMap[key] = this.updateVersionInLocations(
        regionEndpointsMap[key],
        resourceType.defaultApiVersion
      );
    }

    resourceType.endpoints = this.transferToList(regionEndpointsMap);
  }

  execute() {
    var resourceTypes = this.options.content.resourceTypes;
    if (!resourceTypes || !resourceTypes.length) {
      return this.options.content;
    }
    for (var i = 0; i < resourceTypes.length; i++) {
      var resourceType = resourceTypes[i];
      if (this.options.skipResourceTypes.indexOf(resourceType.name) >= 0) {
        continue;
      }
      this.updateVersionToResourceType(resourceType);
    }
    return this.options.content;
  }
}

export default ApiVersionGenerator;
