'use strict';

import ApiVersionGenerator from './apiVersionGenerator.js';

class RemoveApiGenerator extends ApiVersionGenerator {
  constructor(options) {
    super(options);
    if (!options.apiVersion || !options.apiVersion.length) {
      throw new Error('options.apiVersion is not set.');
    }
    this.options = Object.assign(
      {
        afec: [],
        skipResourceTypes: []
      },
      options
    );
  }

  updateVersionInLocations(list, defaultApiVersion) {
    if (!list || !list.length) {
      return list;
    }

    var indexes = this.findItemIndexesByAfec(list, this.options.afec);

    // because this will remove item from list, it search from tail to head
    for (var i = indexes.length - 1; i >= 0; i--) {
      var item = list[indexes[i]];
      var apiIndex = item.apiVersions.indexOf(this.options.apiVersion);
      if (apiIndex > -1) {
        item.apiVersions.splice(apiIndex, 1);

        if (
          item.apiVersions.length == 1 &&
          item.apiVersions[0] === defaultApiVersion
        ) {
          // remove this block in list
          list.splice(indexes[i], 1);
        }
      }
    }
    return list;
  }
}

export default RemoveApiGenerator;
