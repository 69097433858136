'use strict';

import ApiVersionGenerator from './apiVersionGenerator.js';
import { clone } from './utils.js';

class AddApiGenerator extends ApiVersionGenerator {
  constructor(options) {
    super(options);
    if (!options.apiVersion) {
      throw new Error('options.apiVersion is not set or empty.');
    }
    this.options = Object.assign(
      {
        afec: [],
        skipResourceTypes: []
      },
      options
    );
  }

  updateEndpoint(endpoint) {
    (endpoint.apiVersions || []).push(this.options.apiVersion);
    endpoint.apiVersions = Array.from(new Set(endpoint.apiVersions));
  }

  /**
   * Fork items in endpoints with this.options.baseAfec and add them into endpoints.
   * @returns indexes of the cloned item in endpoints
   */
  forkItemsWithBaseAfec(endpoints, defaultApiVersion) {
    if (this.options.baseAfec === undefined) {
      return [];
    }
    var indexes = this.findItemIndexesByAfec(endpoints, this.options.baseAfec);
    if (!indexes || !indexes.length) {
      console.warn(
        'Cannot fork a block for ' + this.arrayToString(endpoints[0].locations)
      );
      return [];
    }

    var clones = [];
    for (var i = 0; i < indexes.length; i++) {
      var item = clone(endpoints[indexes[i]]);
      item.requiredFeatures = this.options.afec;
      item.apiVersions = [defaultApiVersion];
      clones.push(endpoints.length);
      endpoints.push(item);
    }
    return clones;
  }

  updateVersionInLocations(list, defaultApiVersion) {
    if (!list || !list.length) {
      return list;
    }

    var indexes = this.findItemIndexesByAfec(list, this.options.afec);

    if (!indexes || !indexes.length) {
      indexes = this.forkItemsWithBaseAfec(list, defaultApiVersion);
    }

    for (var i = 0; i < indexes.length; i++) {
      var item = list[indexes[i]];
      this.updateEndpoint(item);
    }
    return list;
  }
}

export default AddApiGenerator;
