const README = {
  name: 'ARM manfiest editor',
  description:
    'This site provides serveral common action to help to update an existing ARM manfiest.',
  steps: [
    '1. Paste your ARM manifest JSON in the left panel',
    '2. Update the options you want to handle in the \'options.json\'',
    "3. Click 'Run'",
    '4. Obtain the output in right panel and see the diff',
  ],
};

export default README;
