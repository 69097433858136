import Generator from './generator.js';

class RegionGenerator extends Generator {
  constructor(options) {
    super(options);
    if (!options.regions || !options.regions.length) {
      throw new Error('options.regions is not set or empty.');
    }
    this.options = Object.assign(
      {
        afec: [],
      },
      options
    );
  }

  execute() {
    return this.options.content;
  }
}

export default RegionGenerator;
