'user strict';

import { generatorType } from './factory.js';
import Generator from './generator.js';
import execute from './index.js';

class BatchGenerator extends Generator {
  constructor(options) {
    super(options);

    if (!options.options || !Array.isArray(options.options)) {
      throw new Error('options is not found or it is not an array of options');
    }
  }

  execute() {
    if (!this.options.options.length) {
      return this.options.content;
    }

    for (var i = 0; i < this.options.options.length; i++) {
      var option = this.options.options[i];
      option.content = this.options.content;
      option.type = generatorType[option.type];
      this.options.content = execute(option);
    }
    return this.options.content;
  }
}

export default BatchGenerator;
