import { DiffEditor } from '@monaco-editor/react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import React, { Component, useRef } from 'react';
import README from './instruction.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './editor.css';
import BatchGenerator from './generator/batchGenerator.js';
import execute from './generator/index.js';

const options = {
  minimap: {
    enabled: false,
  },
  originalEditable: true,
};

class ManifestOutput extends Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();

    this.state = {
      files: {
        "manifest": JSON.stringify(README, null, 4),
        "options": "[]"
      },
      active: "manifest",
      output: JSON.stringify(README, null, 4)
    }

    this.mountEditor = this.mountEditor.bind(this);
    this.setFile = this.setFile.bind(this);
    this.onError = this.onError.bind(this);
    this.loadJson = this.loadJson.bind(this);
    this.run = this.run.bind(this);
    this.getUpdateFile = this.getUpdateFile.bind(this);
  }

  loadJson(name, files) {
    try {
      return JSON.parse(files[name])
    } catch (err) {
      this.onError('Failed to parse json in ' + name + '.json with error:\n' + err.message);
    }
  }

  onError(msg) {
    this.setState(function () {
      return {
        output: '// ' + msg,
      };
    });
  }

  run() {
    var files = this.getUpdateFile();
    var input = {
      options: this.loadJson('options', files),
      content: this.loadJson('manifest', files),
      type: BatchGenerator
    }

    if (!input.options || !input.content) {
      return;
    }
    
    try {
      var response = execute(input);
      this.setState(() => {
        return { output: JSON.stringify(response, null, 4) }
      });
    } catch (err) {
      this.onError('Failed to generate ARM manifest with error: \n' + err.message)
    }
  }

  mountEditor(editor, monaco) {
    this.editorRef.current = editor;
  }

  getUpdateFile() {
    var files = this.state.files;
    files[this.state.active] = this.editorRef.current.getOriginalEditor().getValue();
    return files;
  }

  setFile(filename) {
    var files = this.getUpdateFile();
    this.setState(() => {
      return {
        active: filename,
        files: files
      }
    })
  }

  render() {
    return (
      <div >
        <Button
          variant="success"
          size="sm"
          className="Run .bg-success "
          onClick={this.run}
        >Run</Button>
        <Tabs
          activeKey={this.state.active}
          className="mb-0 tab"
          onSelect={(key, _) => this.setFile(key)}
        >
          <Tab eventKey="manifest" title="manifest.json" />
          <Tab eventKey="options" title="options.json" />
        </Tabs>

        <DiffEditor
          height="94vh"
          width="100%"
          theme="vs-dark"
          originalLanguage="json"
          modifiedLanguage="json"
          keepCurrentOriginalModel={true}
          options={options}
          original={this.state.files[this.state.active]}
          modified={this.state.output}
          onMount={this.mountEditor}
        />
      </div>
    );
  }
}

export default ManifestOutput;
