import './App.css';
import ManifestOutput from './ManifestOutput.js';
import React, { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <span className="App-header">ARM manifest editor</span>
        <ManifestOutput className="main" />
      </div>
    );
  }
}

export default App;
