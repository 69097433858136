class Generator {
  constructor(options) {
    if (!this.execute) {
      throw new Error('Generator must implement execute()');
    }

    if (!options.content) {
      throw new Error('options.content is not set.');
    }
    this.options = options;
  }
}

export default Generator;
